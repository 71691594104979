import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bank: {},
}

export const bankSlice = createSlice({
    name:'bank',
    initialState,
    reducers:{
        setBankAccount: (state, action) =>{
            state.bank = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setBankAccount } = bankSlice.actions

export default bankSlice.reducer