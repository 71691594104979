import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    titlePage: 'Dashboard',
    newJobsCount: 0,
    alert: {},
    language: 'ID'
}

export const globalSlice = createSlice({
    name:'global',
    initialState,
    reducers:{
        setTitlePage: (state, action) =>{
            state.titlePage = action.payload
        },
        setNewJobsCount:(state, action) => {
            state.newJobsCount = action.payload
        },
        setAlert:(state, action) => {
            state.alert = action.payload
        },
        setLanguage:(state, action) => {
            state.language = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setTitlePage,setNewJobsCount, setAlert, setLanguage } = globalSlice.actions

export default globalSlice.reducer