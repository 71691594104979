import ActionType from './actionType'

const globalState = {
    totalOrder: 0,
    sudahLogin: false,
}

const rootReducerCustom = (state = globalState, action) => {
    switch (action.type) {
        case ActionType.ADD_AUTH:
            console.log("DISPATCH ADD_AUTH", action.payload)
            localStorage.setItem("access_token",action.payload.access_token)
            return {
                ...state,
                sudahLogin:true,
            }
        case ActionType.REMOVE_AUTH:
            console.log("DISPATCH REMOVE_ATUH")
            return {
                ...state,
                sudahLogin:false,
            }
        case ActionType.ACCEPT_VENDOR_TRANSLATE:
            console.log("Dispatch accept vendor translate")
        default:
            return state;
    }
}


export default rootReducerCustom