import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: 0,
    process:0,
}

export const balanceSlice = createSlice({
    name:'balance',
    initialState,
    reducers:{
        setBalance: (state, action) =>{
            state.value = action.payload
        },
        setProcess: (state, action) =>{
            state.process = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setBalance, setProcess } = balanceSlice.actions

export default balanceSlice.reducer