// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import rootReducerCustom from './reducerCustom'
import auth from './Slice/Auth'
import balance from './Slice/Balance'
import BankAccount from './Slice/BankAccount'
import Profile from './Slice/Profile'
import Global from './Slice/Global'


const rootReducer = { navbar, layout, rootReducerCustom, auth, balance, BankAccount, Profile, Global }

export default rootReducer
