import axios from "axios";
import { RouteApi, RouteBase } from "./config";

const Put = (path, root, data, adtUrl = "") => {
  const promise = new Promise((resolve, reject) => {
    axios
      .put(`${!root ? RouteApi + adtUrl : RouteBase}/${path}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then(function (response) {
        // handle success
        resolve(response);
      })
      .catch(function (error) {
        // handle error
        if (error.response.status == 401) {
          window.location = "/logout";
        }
        reject(error);
      });
  });
  return promise;
};

export default Put;
