import axios from "axios";
import { RouteApi, RouteBase } from "./config";

const Get = (path, params, root, adtUrl = "") => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(`${root == false ? RouteApi + adtUrl : RouteBase}/${path}`, {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
        timeout: 5000,
      })
      .then(function (response) {
        // handle success
        resolve(response.data);
      })
      .catch(function (error) {
        // handle error
        if (error.response.status == 401) {
          window.location = "/logout";
        }
        reject(error);
      });
  });
  return promise;
};

export default Get;
